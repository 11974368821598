import { Button, Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import { t } from "@/utils";
import Lottie from "@/components/Lottie";
import onboardingAnimationLight from "assets/animations/onboarding1_light.json?url";
import onboardingAnimationDark from "assets/animations/onboarding1_dark.json?url";
import { WizardProps } from "../Wizard";

export default function AddCamera({ next, push, wizard }: WizardProps) {
  const animation = useColorModeValue(onboardingAnimationLight, onboardingAnimationDark);
  return (
    <Flex grow={1} direction="column" alignItems="center">
      <NavigationBar onClose={wizard.close} props={{ my: "1rem" }} />
      <Lottie src={animation} props={{ mb: "2rem", height: "272px" }} />
      <Heading as="h4" mb="0.75rem">
        {t("pairing.add.camera.step1")}
      </Heading>
      <Text mb="0.75rem">{t("usePhoneTabletComputer")}</Text>

      <Button
        variant="link"
        color="primary"
        mt="0.5rem"
        fontWeight={700}
        onClick={() =>
          push("device-select", {
            stepDirection: "next",
            stateUpdate: { previousStepId: "add-camera" }
          })
        }
      >
        {t("pairing.help.install.title")}
      </Button>

      <Button mt="48" bg="primary" color="on-primary" py="18" minW="18.5rem" onClick={next}>
        {t("buttons.continue")}
      </Button>
    </Flex>
  );
}
